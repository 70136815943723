// FileUpload
export const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAIL = "FILE_UPLOAD_FAIL";
export const FILE_UPLOAD_RESET = "FILE_UPLOAD_RESET";
export const FILE_UPLOAD_PROGRESS = "FILE_UPLOAD_PROGRESS";

// FileList
export const FILE_LIST_REQUEST = "FILE_FILE_REQUEST";
export const FILE_LIST_SUCCESS = "FILE_LIST_SUCCESS";
export const FILE_LIST_FAIL = "FILE_UPLOAD_FAIL";

// DeleteFile
export const FILE_DELETE_REQUEST = "FILE_DELETE_REQUEST";
export const FILE_DELETE_SUCCESS = "FILE_DELETE_SUCCESS";
export const FILE_DELETE_FAIL = "FILE_DELETE_FAIL";
export const FILE_DELETE_RESET = "FILE_DELETE_RESET";

//DownloadFile
export const FILE_DOWNLOAD_REQUEST = "FILE_DOWNLOAD_REQUEST";
export const FILE_DOWNLOAD_SUCCESS = "FILE_DOWNLOAD_SUCCESS";
export const FILE_DOWNLOAD_FAIL = "FILE_DOWNLOAD_FAIL";
export const FILE_DOWNLOAD_RESET = "FILE_DOWNLOAD_RESET";
