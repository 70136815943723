import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomInput from "../components/CustomInput";
import CustomPrimaryButton from "../components/CustomPrimaryButton";
import CustomLoadingSpinner from "../components/CustomLoadingSpinner";

const LoginScreen = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate("/home");
    }
  }, [navigate, userInfo]);

  const handleLogin = (e) => {
    e.preventDefault();

    // Check if username or password is empty
    if (!username || !password) {
      toast.warning("Both email/username and password are required");
      return;
    }

    // Convert email to lowercase before dispatching login
    const normalizedUsername = username.includes("@")
      ? username.toLowerCase()
      : username;

    dispatch(login(normalizedUsername, password));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleRegister = () => {
    navigate("/register");
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 font-markpro">
      <div className="bg-gray-800 p-12 rounded-lg shadow-lg w-full max-w-4xl text-white">
        <div className="text-center mb-10">
          <h1 className="text-5xl font-bold text-green-400">
            Welcome to zAKI AI
          </h1>
        </div>
        <form onSubmit={handleLogin} className="space-y-8">
          <CustomInput
            label="Email  or Username"
            id="username"
            type="text"
            placeholder="Enter your email or username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <CustomInput
            label="Password"
            id="password"
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <CustomPrimaryButton
            type="submit"
            disabled={loading}
            fullWidth="true"
          >
            {loading ? (
              <CustomLoadingSpinner size={6} text="Logging in..." />
            ) : (
              "Login"
            )}
          </CustomPrimaryButton>
        </form>
        <div className="mt-6 text-center space-y-2">
          <div>
            <button
              onClick={handleForgotPassword}
              className="text-white-100 hover:underline mt-2"
            >
              Forgot your password?
            </button>
          </div>
          <div>
            <span className="text-gray-400">Don't have an account?</span>
            <button
              onClick={handleRegister}
              className="ml-2 text-green-400 hover:underline"
            >
              Register here
            </button>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
};

export default LoginScreen;
