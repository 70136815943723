import {
  CONNECTION_LIST_REQUEST,
  CONNECTION_LIST_SUCCESS,
  CONNECTION_LIST_FAIL,
  CONNECTION_LIST_REST,
  CONNECTION_TEST_REQUEST,
  CONNECTION_TEST_SUCCESS,
  CONNECTION_TEST_FAIL,
  CONNECTION_TEST_REST,
  CONNECTION_CREATE_REQUEST,
  CONNECTION_CREATE_SUCCESS,
  CONNECTION_CREATE_FAIL,
  CONNECTION_CREATE_REST,
  CONNECTION_DELETE_SUCCESS,
  CONNECTION_DELETE_FAIL,
  CONNECTION_DELETE_REST,
  CONNECTION_DELETE_REQUEST,
  CONNECTION_SHOW_SCHEMA_SUCCESS,
  CONNECTION_SHOW_SCHEMA_FAIL,
  CONNECTION_SHOW_SCHEMA_REQUEST,
  CONNECTION_SHOW_SCHEMA_REST,
} from "../constants/connectionConstants";

export const connectionListReducer = (state = { connections: [] }, action) => {
  switch (action.type) {
    case CONNECTION_LIST_REQUEST:
      return {
        loading: true,
      };
    case CONNECTION_LIST_SUCCESS:
      return {
        loading: false,
        connections: action.payload,
      };
    case CONNECTION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONNECTION_LIST_REST:
      return {
        connections: [],
      };
    default:
      return state;
  }
};

export const connectionTestReducer = (state = {}, action) => {
  switch (action.type) {
    case CONNECTION_TEST_REQUEST:
      return {
        loading: true,
      };
    case CONNECTION_TEST_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case CONNECTION_TEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONNECTION_TEST_REST:
      return {};
    default:
      return state;
  }
};

export const connectionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CONNECTION_CREATE_REQUEST:
      return {
        loading: true,
      };
    case CONNECTION_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case CONNECTION_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONNECTION_CREATE_REST:
      return {};
    default:
      return state;
  }
};

export const connectionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CONNECTION_DELETE_REQUEST:
      return {
        loading: true,
      };
    case CONNECTION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case CONNECTION_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONNECTION_DELETE_REST:
      return {};
    default:
      return state;
  }
};
export const connectionSchemaReducer = (state = { schema: [] }, action) => {
  switch (action.type) {
    case CONNECTION_SHOW_SCHEMA_REQUEST:
      return {
        loading: true,
      };
    case CONNECTION_SHOW_SCHEMA_SUCCESS:
      return {
        loading: false,
        schema: action.payload,
      };
    case CONNECTION_SHOW_SCHEMA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONNECTION_SHOW_SCHEMA_REST:
      return {
        schema: [],
      };
    default:
      return state;
  }
};
