import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listUsers,
  createUser,
  deleteUser,
  activateUser,
  deactivateUser,
  updateUser,
  logout,
} from "../actions/userActions";
import PopUpModal from "../components/CustomPopUpModal";
import CustomTable from "../components/CustomTable";
import { FaCheckCircle, FaTimesCircle, FaEdit, FaTrash } from "react-icons/fa";
import TableButton from "../components/CustomTableButton";
import Message from "../components/CustomMessage";
import Header from "../components/Header";
import "react-toastify/dist/ReactToastify.css";
import CustomPrimaryButton from "../components/CustomPrimaryButton";
import CustomLoading from "../components/CustomLoading";
import {
  USER_ACTIVATE_RESET,
  USER_CREATE_RESET,
  USER_DEACTIVATE_RESET,
  USER_DELETE_RESET,
  USER_EXPIRED_TOKEN_RESET,
  USER_UPDATE_RESET,
} from "../constants/userConstants";
import { toast, ToastContainer } from "react-toastify";
import CustomAlertModal from "../components/CustomAlertModal";

const UserListScreen = () => {
  const dispatch = useDispatch();
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showMoreModalOpen, setShowMoreModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userDetails, setUserDetails] = useState({
    address: "",
    full_subdomain: "",
    phone_number: "",
    username: "",
    is_active: false,
    id: null,
    _id: null,
  });

  const [showTokenModal, setShowTokenModal] = useState(false);

  const userList = useSelector((state) => state.usersList);
  const { loading, error, users } = userList;

  const userCreate = useSelector((state) => state.userCreate);
  const { success: successCreate, error: errorCreate } = userCreate;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete, error: errorDelete } = userDelete;

  const userDeactivate = useSelector((state) => state.userDeactivate);
  const { success: successDeactivate, error: errorDeactivate } = userDeactivate;

  const userActivate = useSelector((state) => state.userActivate);
  const { success: successActivate, error: errorActivate } = userActivate;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { success: successUpdate, error: errorUpdate } = userUpdate;

  const tokenExpired = useSelector((state) => state.tokenExpired);
  const { error: TokenMessage } = tokenExpired;

  useEffect(() => {
    dispatch(listUsers());
    if (successCreate) {
      toast.success("user successfully created!");
      dispatch({ type: USER_CREATE_RESET });
    }
    if (successUpdate) {
      toast.success("user successfully updated!");
      dispatch({ type: USER_UPDATE_RESET });
    }
    if (successDeactivate) {
      toast.success("user successfully deactivated!");
      dispatch({ type: USER_DEACTIVATE_RESET });
    }
    if (successActivate) {
      toast.success("user successfully activated!");
      dispatch({ type: USER_ACTIVATE_RESET });
    }
    if (successDelete) {
      toast.success("user successfully deleted!");
      dispatch({ type: USER_DELETE_RESET });
    }
    if (TokenMessage) {
      toast.error(TokenMessage);
      setShowTokenModal(true);
    }
    if (errorCreate) {
      toast.error(`Error during creating user : ${errorCreate}`);
      dispatch({ type: USER_CREATE_RESET });
    }
    if (errorUpdate) {
      toast.error(`Error during updating user : ${errorUpdate}`);
      dispatch({ type: USER_UPDATE_RESET });
    }
    if (errorActivate) {
      toast.error(`Error during activating user : ${errorActivate}`);
      dispatch({ type: USER_ACTIVATE_RESET });
    }
    if (errorDeactivate) {
      toast.error(`Error during deactivating user : ${errorDeactivate}`);
      dispatch({ type: USER_DEACTIVATE_RESET });
    }
    if (errorDelete) {
      toast.error(`Error during deleting user: ${errorDelete}`);
      dispatch({ type: USER_DELETE_RESET });
    }
  }, [
    dispatch,
    successCreate,
    successDelete,
    successDeactivate,
    successActivate,
    successUpdate,
    errorUpdate,
    errorActivate,
    errorDeactivate,
    errorDelete,
    errorCreate,
    TokenMessage,
  ]);

  const openModal = (type, user = null) => {
    setModalType(type);
    setModalOpen(true);

    if (user) {
      setSelectedUserId(user.id);
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setIsAdmin(user.isAdmin);
      setIsCreateMode(false);
    } else {
      setSelectedUserId(null);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setIsAdmin(false);
      setIsCreateMode(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setShowMoreModalOpen(false);
  };
  const handleTokenModalConfirm = () => {
    setShowTokenModal(false);
    dispatch({ type: USER_EXPIRED_TOKEN_RESET });
    dispatch(logout());
  };
  const handleCreateUser = () => {
    openModal("create");
  };

  const handleDeleteUser = (user) => {
    openModal("delete", user);
  };

  const handleActivateUser = (user) => {
    openModal("activate", user);
  };

  const handleDeactivateUser = (user) => {
    openModal("deactivate", user);
  };

  const handleEditUser = (user) => {
    openModal("edit", user);
  };

  const handleConfirm = async () => {
    switch (modalType) {
      case "create":
        await dispatch(
          createUser(firstName, lastName, email, password, isAdmin),
        );
        break;
      case "edit":
        await dispatch(
          updateUser(selectedUserId, firstName, lastName, email, isAdmin),
        );
        break;
      case "delete":
        await dispatch(deleteUser(selectedUserId));
        break;
      case "activate":
        await dispatch(activateUser(selectedUserId));
        break;
      case "deactivate":
        await dispatch(deactivateUser(selectedUserId));
        break;

      default:
        break;
    }
    closeModal();
  };

  const columns = [
    { Header: "E-Mail", accessor: "email", is_Action: false },
    { Header: "First Name", accessor: "first_name", is_Action: false },
    { Header: "Last Name", accessor: "last_name", is_Action: false },
    {
      Header: "Active",
      accessor: "is_active",
      Cell: ({ value }) =>
        value ? (
          <FaCheckCircle className="text-green-500 ml-5" title="Active" />
        ) : null,
      is_Action: false,
    },
    {
      Header: "Admin",
      accessor: "isAdmin",
      Cell: ({ value }) =>
        value ? (
          <FaCheckCircle className="text-green-500 ml-5" title="Admin" />
        ) : null,
      is_Action: false,
    },
    {
      Header: "Activate",
      Cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            {row.is_active ? (
              <TableButton
                variant="warning"
                size="small"
                onClick={() => handleDeactivateUser(row)}
                title="Deactivate"
              >
                <FaTimesCircle className="text-white" />
              </TableButton>
            ) : (
              <TableButton
                variant="success"
                size="small"
                onClick={() => handleActivateUser(row)}
                title="Activate"
              >
                <FaCheckCircle className="text-white" />
              </TableButton>
            )}
          </div>
        );
      },
      is_Action: true,
    },
    {
      Header: "Edit/Delete",
      Cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <TableButton
              variant="warning"
              size="small"
              onClick={() => handleEditUser(row)}
              title="Edit"
            >
              <FaEdit className="text-white" />
            </TableButton>
            <TableButton
              variant="danger"
              size="small"
              onClick={() => handleDeleteUser(row)}
              title="Delete"
            >
              <FaTrash className="text-white" />
            </TableButton>
          </div>
        );
      },
      is_Action: true,
    },
    {
      Header: "Show More",
      Cell: ({ row }) => {
        return (
          <TableButton
            variant="success"
            size="small"
            onClick={() => handleShowMore(row)}
            title="Show More"
          >
            Show More
          </TableButton>
        );
      },
      is_Action: true,
    },
  ];
  const handleShowMore = (user) => {
    setSelectedUserId(user.id);
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    setIsAdmin(user.isAdmin);
    setUserDetails({
      address: user.address,
      full_subdomain: user.full_subdomain,
      phone_number: user.phone_number,
      username: user.username,
      is_active: user.is_active,
      id: user.id,
      _id: user._id,
    });
    setShowMoreModalOpen(true);
  };
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      <main className="container mx-auto mt-8 p-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-bold">Users</h1>
          <CustomPrimaryButton
            onClick={handleCreateUser}
            size="md"
            textSize="text-2l"
          >
            Create User
          </CustomPrimaryButton>
        </div>
        {loading ? (
          <CustomLoading message="Fetching user data..." />
        ) : error ? (
          <Message message={error} type="error" />
        ) : (
          <CustomTable columns={columns} data={users} itemsPerPage={10} />
        )}
      </main>

      <PopUpModal
        isOpen={modalOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        title={
          modalType === "create"
            ? "Create User"
            : modalType === "edit"
              ? "Edit User"
              : modalType === "delete"
                ? "Delete User"
                : modalType === "activate"
                  ? "Activate User"
                  : "Deactivate User"
        }
      >
        {modalType === "create" || modalType === "edit" ? (
          <>
            <div className="mb-4">
              <label className="flex justify-between items-center  mb-2">
                <label className="block">Admin</label>
                <div className="relative">
                  <input
                    type="checkbox"
                    checked={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.checked)}
                    className="sr-only"
                  />
                  <div
                    className={`block w-11 h-6 rounded-full ${isAdmin ? "bg-green-500" : "bg-gray-400"}`}
                  ></div>
                  <div
                    className={`dot absolute left-1 top-0.5 bg-white w-5 h-5 rounded-full transition ${isAdmin ? "transform translate-x-4" : ""}`}
                  ></div>
                </div>
              </label>

              <label className="block">First Name</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="bg-gray-700 text-white p-2 rounded w-full mt-1"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block">Last Name</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="bg-gray-700 text-white p-2 rounded w-full mt-1"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-700 text-white p-2 rounded w-full mt-1"
                required
              />
            </div>
            {isCreateMode && (
              <div className="mb-4">
                <label className="block">Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-700 text-white p-2 rounded w-full mt-1"
                  required
                />
              </div>
            )}
          </>
        ) : modalType === "delete" ? (
          <p>Are you sure you want to delete this user?</p>
        ) : modalType === "activate" ? (
          <p>Are you sure you want to activate this user?</p>
        ) : (
          <p>Are you sure you want to deactivate this user?</p>
        )}
      </PopUpModal>
      <CustomAlertModal
        isOpen={showTokenModal}
        onConfirm={handleTokenModalConfirm}
        title="Session Expired"
      >
        <p>{TokenMessage}</p>
      </CustomAlertModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <PopUpModal
        isOpen={showMoreModalOpen}
        onConfirm={closeModal}
        title="User Details"
      >
        <div className="p-8 bg-gray-900 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold text-white mb-6">
            User Information
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-base text-gray-300">
            {/* Left column */}
            <div>
              <div className="mb-4">
                <p className="font-semibold text-gray-400">
                  <strong>ID:</strong>
                </p>
                <p className="truncate">{selectedUserId}</p>
              </div>
              <div className="mb-4">
                <p className="font-semibold text-gray-400">
                  <strong>First Name:</strong>
                </p>
                <p className="truncate">{firstName}</p>
              </div>
              <div className="mb-4">
                <p className="font-semibold text-gray-400">
                  <strong>Last Name:</strong>
                </p>
                <p className="truncate">{lastName}</p>
              </div>
              <div className="mb-4">
                <p className="font-semibold text-gray-400">
                  <strong>Username:</strong>
                </p>
                <p className="truncate">{userDetails.username}</p>
              </div>
            </div>

            {/* Right column */}
            <div>
              <div className="mb-4">
                <p className="font-semibold text-gray-400">
                  <strong>Email:</strong>
                </p>
                <p className="truncate">{email}</p>
              </div>
              <div className="mb-4">
                <p className="font-semibold text-gray-400">
                  <strong>Phone Number:</strong>
                </p>
                <p className="truncate">{userDetails.phone_number}</p>
              </div>
              <div className="mb-4">
                <p className="font-semibold text-gray-400">
                  <strong>Address:</strong>
                </p>
                <p className="truncate">{userDetails.address}</p>
              </div>
              <div className="mb-4">
                <p className="font-semibold text-gray-400">
                  <strong>Full Subdomain:</strong>
                </p>
                <p className="truncate">{userDetails.full_subdomain}</p>
              </div>
            </div>
          </div>

          {/* Status Section */}
          <div className="mt-6 border-t border-gray-700 pt-4 text-gray-300">
            <div className="flex items-center mb-3">
              <p className="font-semibold text-gray-400 mr-2">Admin:</p>
              {isAdmin ? (
                <span className="text-green-500 flex items-center">
                  <FaCheckCircle className="mr-1" /> Yes
                </span>
              ) : (
                <span className="text-red-500 flex items-center">
                  <FaTimesCircle className="mr-1" /> No
                </span>
              )}
            </div>
            <div className="flex items-center">
              <p className="font-semibold text-gray-400 mr-2">Active:</p>
              {userDetails.is_active ? (
                <span className="text-green-500 flex items-center">
                  <FaCheckCircle className="mr-1" /> Yes
                </span>
              ) : (
                <span className="text-red-500 flex items-center">
                  <FaTimesCircle className="mr-1" /> No
                </span>
              )}
            </div>
          </div>
        </div>
      </PopUpModal>
    </div>
  );
};

export default UserListScreen;
