import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaSignOutAlt, FaBars } from "react-icons/fa";
import { logout } from "../actions/userActions";
import CustomLoadingSpinner from "./CustomLoadingSpinner";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loadingLogout } = userLogin;

  const [isMenuOpen, setIsMenuOpen] = useState(false); // State für Menü

  const handleLogout = () => {
    dispatch(logout());
  };

  const location = useLocation();

  return (
    <header className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Hamburger Icon */}
        <div className="md:hidden">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <FaBars className="text-2xl" />
          </button>
        </div>

        {/* Navigation Links */}
        <nav
          className={`flex-col md:flex md:flex-row md:space-x-8 mx-auto ${isMenuOpen ? "flex" : "hidden"} md:flex`}
        >
          <Link
            to="/home"
            className={`text-lg font-bold transition duration-300 ${location.pathname === "/home" ? "text-green-500" : "text-white"} hover:text-green-400`}
          >
            Home
          </Link>
          <Link
            to="/analyze"
            className={`text-lg font-bold transition duration-300 ${location.pathname === "/analyze" ? "text-green-500" : "text-white"} hover:text-green-400`}
          >
            Analyze
          </Link>
          <Link
            to="/connections"
            className={`text-lg font-bold transition duration-300 ${location.pathname === "/connections" ? "text-green-500" : "text-white"} hover:text-green-400`}
          >
            Connections
          </Link>

          {userInfo && userInfo.isAdmin && (
            <Link
              to="/admin/files"
              className={`text-lg font-bold transition duration-300 ${location.pathname === "/admin/files" ? "text-green-500" : "text-white"} hover:text-green-400`}
            >
              Files
            </Link>
          )}

          {userInfo && userInfo.isAdmin && (
            <Link
              to="/admin/tables"
              className={`text-lg font-bold transition duration-300 ${location.pathname === "/admin/tables" ? "text-green-500" : "text-white"} hover:text-green-400`}
            >
              Tables
            </Link>
          )}
          {userInfo && userInfo.isAdmin && (
            <Link
              to="/admin/userlist"
              className={`text-lg font-bold transition duration-300 ${location.pathname === "/admin/userlist" ? "text-green-500" : "text-white"} hover:text-green-400`}
            >
              Users
            </Link>
          )}
        </nav>

        {userInfo && (
          <button
            onClick={handleLogout}
            disabled={loadingLogout}
            className="flex items-center space-x-2 bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            {loadingLogout ? (
              <CustomLoadingSpinner size={5} text="Logging out..." />
            ) : (
              <>
                <FaSignOutAlt />
                <span>Logout</span>
              </>
            )}
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
