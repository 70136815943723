import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../components/CustomTable";
import Message from "../components/CustomMessage";
import Header from "../components/Header";
import "react-toastify/dist/ReactToastify.css";
import CustomPrimaryButton from "../components/CustomPrimaryButton";
import CustomLoading from "../components/CustomLoading";
import { toast, ToastContainer } from "react-toastify";
import "../css/CustomTableStyle.css";
import {
  createConnection,
  deleteConnection,
  listConnections,
  schemaConnections,
  testConnections,
} from "../actions/connectionActions";
import CreateFormComponent from "../components/CreateFormComponent";
import {
  CONNECTION_CREATE_REST,
  CONNECTION_DELETE_REST,
  CONNECTION_SHOW_SCHEMA_REST,
  CONNECTION_TEST_REST,
} from "../constants/connectionConstants";
import { logout } from "../actions/userActions";
import { USER_EXPIRED_TOKEN_RESET } from "../constants/userConstants";
import CustomAlertModal from "../components/CustomAlertModal";
import TableButton from "../components/CustomTableButton";
import { FaCheckCircle, FaTimesCircle, FaTrash } from "react-icons/fa";
import CustomPopUpModal from "../components/CustomPopUpModal";
import PopUpModal from "../components/CustomPopUpModal";

const ConnectionScreen = () => {
  const dispatch = useDispatch();
  const [connectionToDelete, setConnectionToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [schemaDetails, setSchemaDetails] = useState(null);

  const connectionList = useSelector((state) => state.connectionList);
  const { loading, error, connections } = connectionList;
  const connectionSchema = useSelector((state) => state.connectionSchema);
  const {
    loading: loadingSchema,
    error: errorSchema,
    schema: schema,
  } = connectionSchema;
  const connectionTest = useSelector((state) => state.connectionTest);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const {
    loading: loadingTest,
    error: errorTest,
    success: successTest,
  } = connectionTest;
  const connectionCreate = useSelector((state) => state.connectionCreate);
  const { error: errorCreate, success: successCreate } = connectionCreate;
  const ConnectionDelete = useSelector((state) => state.connectionDelete);
  const { error: deleteError, success: deleteSuccess } = ConnectionDelete;
  const tokenExpired = useSelector((state) => state.tokenExpired);
  const { error: TokenMessage } = tokenExpired;
  const [showPopup, setShowPopup] = useState(false);
  const [step, setStep] = useState(0);
  const [connectionName, setConnectionName] = useState("");
  const [dbSystem, setDbSystem] = useState("");
  const [dbName, setDbname] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    dispatch(listConnections());
    if (successCreate) {
      toast.success("connection successfully created!");
      dispatch({ type: CONNECTION_CREATE_REST });
    }
    if (successTest) {
      toast.success("connection successfully");
      dispatch({ type: CONNECTION_TEST_REST });
    }
    if (deleteSuccess) {
      toast.success("connection successfully deleted");
      setIsDeleteModalOpen(false);
      dispatch({ type: CONNECTION_DELETE_REST });
    }
    if (TokenMessage) {
      toast.error(TokenMessage);
      setShowTokenModal(true);
    }
    if (errorCreate) {
      toast.error(`Error during creating connection : ${errorCreate}`);
      dispatch({ type: CONNECTION_CREATE_REST });
    }
    if (errorTest) {
      toast.error(`Error during testing connection : ${errorTest}`);
      dispatch({ type: CONNECTION_TEST_REST });
    }
    if (deleteError) {
      toast.error(`Error during deleting connection : ${deleteError}`);
      setIsDeleteModalOpen(false);
      dispatch({ type: CONNECTION_DELETE_REST });
    }
    if (errorSchema) {
      toast.error(`Error during loading connection schema : ${errorSchema}`);
      setIsDeleteModalOpen(false);
      dispatch({ type: CONNECTION_SHOW_SCHEMA_REST });
    }
    if (schema) {
      setSchemaDetails(schema);
    }
  }, [
    schema,
    dispatch,
    successCreate,
    successTest,
    deleteSuccess,
    errorCreate,
    errorTest,
    deleteError,
    TokenMessage,
  ]);

  const handleCreateConnection = async () => {
    dispatch(
      createConnection(
        connectionName,
        dbSystem,
        host,
        port,
        password,
        "W",
        user,
        dbName,
      ),
    );
    setConnectionName("");
    setDbSystem("");
    setHost("");
    setPort("");
    setUser("");
    setPassword("");
    setDbname("");
    setStep(0);
    setShowPopup(false);
  };

  const handleTestConnection = async () => {
    dispatch(testConnections(dbSystem, host, port, password, user, dbName));
  };
  const handleTokenModalConfirm = () => {
    setShowTokenModal(false);
    dispatch({ type: USER_EXPIRED_TOKEN_RESET });
    dispatch(logout());
  };
  const handleConfirmDelete = () => {
    dispatch(deleteConnection(connectionToDelete.id));
  };
  const handleDeleteConnection = (connection) => {
    setConnectionToDelete(connection);
    setIsDeleteModalOpen(true);
  };
  const steps = [
    {
      title: "Select Database System",
      fields: (
        <>
          <label>Connection Name</label>
          <input
            type="text"
            placeholder="Connection Name"
            value={connectionName}
            onChange={(e) => setConnectionName(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded w-full mt-1"
          />
          <label>Datenbank System</label>
          <select
            value={dbSystem}
            onChange={(e) => setDbSystem(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded w-full mt-1"
          >
            <option value="">Select Database System</option>
            <option value="postgresql">PostgreSQL</option>
            <option value="mysql">MySQL</option>
            <option value="mariadb">MariaDB</option>
          </select>
        </>
      ),
    },
    {
      title: "Connection Data",
      fields: (
        <>
          <label>Host</label>
          <input
            type="text"
            placeholder="Host"
            value={host}
            onChange={(e) => setHost(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded w-full mt-1"
          />
          <label>Port</label>
          <input
            type="number"
            placeholder="Port"
            value={port}
            onChange={(e) => setPort(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded w-full mt-1"
          />
          <label>User</label>
          <input
            type="text"
            placeholder="User"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded w-full mt-1"
          />
          <label>Database Name</label>
          <input
            type="text"
            placeholder="Database Name"
            value={dbName}
            onChange={(e) => setDbname(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded w-full mt-1"
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded w-full mt-1"
          />
        </>
      ),
    },
    {
      title: "Connection Test",
      fields: (
        <>
          <h3 className="text-lg font-semibold mb-2">Connection Details</h3>
          <div className="bg-gray-700 p-4 rounded mb-4">
            <p>
              <strong>Connection Name:</strong> {connectionName}
            </p>
            <p>
              <strong>Database System:</strong> {dbSystem}
            </p>
            <p>
              <strong>Host:</strong> {host}
            </p>
            <p>
              <strong>Port:</strong> {port}
            </p>
            <p>
              <strong>User:</strong> {user}
            </p>
            <p>
              <strong>Database Name:</strong> {dbName}
            </p>
          </div>

          {loadingTest ? (
            <CustomLoading message="Testing connection data..." />
          ) : errorTest ? (
            <Message message={errorTest} type="error" />
          ) : (
            <div></div>
          )}

          <button
            onClick={handleTestConnection}
            disabled={!host || !port || !user || !password}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Test Connection
          </button>
        </>
      ),
      nextButton: {
        label: "Create", // Der Text für den "Next"-Button im letzten Schritt
        onClick: handleCreateConnection,
      },
    },
  ];

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const isNextDisabled = () => {
    if (step === 0) {
      return !connectionName || !dbSystem;
    } else if (step === 1) {
      return !host || !port || !user || !password;
    }
    return false;
  };

  const allConnections = [];
  if (connections) {
    Object.keys(connections).forEach((key) => {
      if (Array.isArray(connections[key]) && connections[key].length > 0) {
        allConnections.push(...connections[key]);
      }
    });
  }
  const handleShowMore = (connection) => {
    dispatch(schemaConnections(connection.id));
    if (schema && schema.tables) {
      setSchemaDetails(schema);
      setModalOpen(true);
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSchemaDetails(null);
    dispatch({ type: CONNECTION_SHOW_SCHEMA_REST });
  };
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "System", accessor: "system" },
    { Header: "Host", accessor: "host" },
    { Header: "Port", accessor: "port" },
    { Header: "Privileges", accessor: "privileges" },
    { Header: "Created At", accessor: "created_at" },
    { Header: "Created By", accessor: "created_by_name" },
    {
      Header: "Delete",
      Cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <TableButton
              variant="danger"
              size="small"
              onClick={() => handleDeleteConnection(row)}
              title="Delete"
            >
              <FaTrash className="text-white" />
            </TableButton>
          </div>
        );
      },
      is_Action: true,
    },
    {
      Header: "Show More",
      Cell: ({ row }) => {
        return (
          <TableButton
            variant="success"
            size="small"
            onClick={() => handleShowMore(row)}
            title="Show More"
          >
            Show More
          </TableButton>
        );
      },
      is_Action: true,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      <main className="container mx-auto mt-8 p-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <h1 className="text-3xl font-bold mb-2 md:mb-0">Connections</h1>
          <CustomPrimaryButton
            size="md"
            textSize="text-2l"
            onClick={() => setShowPopup(true)}
          >
            Create Connection
          </CustomPrimaryButton>
        </div>
        {loading ? (
          <CustomLoading message="Fetching connection data..." />
        ) : error ? (
          <Message message={error} type="error" />
        ) : (
          <CustomTable columns={columns} data={allConnections} />
        )}
      </main>
      {showPopup && (
        <CreateFormComponent
          onClose={() => setShowPopup(false)}
          step={steps[step]}
          handleNext={handleNext}
          handleBack={handleBack}
          steps={steps}
          currentStep={step}
          nextDisabled={isNextDisabled()}
        />
      )}
      <CustomAlertModal
        isOpen={showTokenModal}
        onConfirm={handleTokenModalConfirm}
        title="Session Expired"
      >
        <p>{TokenMessage}</p>
      </CustomAlertModal>
      {/* Delete confirmation modal */}
      <CustomPopUpModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
      >
        <p>
          Are you sure you want to delete the Connection:{" "}
          {connectionToDelete?.name}?
        </p>
      </CustomPopUpModal>
      <PopUpModal
        isOpen={modalOpen}
        onConfirm={closeModal}
        title="Schema Details"
        className="max-w-8xl w-full rounded-lg shadow-lg bg-gray-800 border border-gray-700"
      >
        {loadingSchema ? (
          <CustomLoading message="Fetching connection data..." />
        ) : (
          <div className="p-6 max-h-[60vh] overflow-y-auto scrollbar-custom">
            <h3 className="text-2xl font-semibold mb-4 text-white">
              Schema Tables
            </h3>
            {schemaDetails && schemaDetails.tables ? (
              <ul className="list-disc pl-5 space-y-2">
                {schemaDetails.tables.map((table, index) => (
                  <li key={index} className="mb-1 break-words text-gray-300">
                    {table}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-300">No tables found.</p>
            )}
          </div>
        )}
      </PopUpModal>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
    </div>
  );
};

export default ConnectionScreen;
