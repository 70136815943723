import { configureStore } from "@reduxjs/toolkit";
import {
  userLoginReducer,
  usersListReducer,
  userDeleteReducer,
  userDeactivateReducer,
  userUpdateReducer,
  userCreateReducer,
  userActivateReducer,
  userExpiredTokenReducer,
  userRegisterReducer,
} from "./reducers/userReducer";
import {
  connectionListReducer,
  connectionTestReducer,
  connectionCreateReducer,
  connectionDeleteReducer,
  connectionSchemaReducer,
} from "./reducers/connectionReducer";
import {
  fileDeleteReducer,
  fileDownloadReducer,
  fileReducer,
} from "./reducers/fileReducer";
import {
  tableListReducer,
  tableCreateReducer,
  tableUpdateReducer,
} from "./reducers/tableReducer";
import { thunk } from "redux-thunk";

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const store = configureStore({
  reducer: {
    tokenExpired: userExpiredTokenReducer,
    userRegister: userRegisterReducer,
    userLogin: userLoginReducer,
    usersList: usersListReducer,
    userDelete: userDeleteReducer,
    userCreate: userCreateReducer,
    userDeactivate: userDeactivateReducer,
    userActivate: userActivateReducer,
    userUpdate: userUpdateReducer,
    file: fileReducer,
    fileDelete: fileDeleteReducer,
    fileDownload: fileDownloadReducer,
    connectionList: connectionListReducer,
    connectionTest: connectionTestReducer,
    connectionCreate: connectionCreateReducer,
    connectionDelete: connectionDeleteReducer,
    connectionSchema: connectionSchemaReducer,
    tableList: tableListReducer,
    tableCreate: tableCreateReducer,
    tableUpdate: tableUpdateReducer,
  },
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
