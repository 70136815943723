import {
  TABLE_LIST_REQUEST,
  TABLE_LIST_SUCCESS,
  TABLE_LIST_FAIL,
  TABLE_CREATE_REQUEST,
  TABLE_CREATE_SUCCESS,
  TABLE_CREATE_FAIL,
  TABLE_CREATE_RESET,
  TABLE_UPDATE_REQUEST,
  TABLE_UPDATE_PROGRESS,
  TABLE_UPDATE_SUCCESS,
  TABLE_UPDATE_FAIL,
  TABLE_UPDATE_RESET,
} from "../constants/tableConstants";

export const tableListReducer = (state = { tables: [] }, action) => {
  switch (action.type) {
    case TABLE_LIST_REQUEST:
      return { loading: true, tables: [] };
    case TABLE_LIST_SUCCESS:
      return { loading: false, tables: action.payload };
    case TABLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const tableCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TABLE_CREATE_REQUEST:
      return { loading: true };
    case TABLE_CREATE_SUCCESS:
      return { loading: false, success: true };
    case TABLE_CREATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case TABLE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const tableUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TABLE_UPDATE_REQUEST:
      return { loading: true };
    case TABLE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case TABLE_UPDATE_PROGRESS:
      return { ...state, uploadProgress: action.payload };
    case TABLE_UPDATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case TABLE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
