import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Switch } from "@headlessui/react";
import { FaEdit, FaInfoCircle } from "react-icons/fa";

import CustomLoadingSpinner from "../components/CustomLoadingSpinner";
import CustomTable from "../components/CustomTable";
import {
  listTables,
  createTable,
  resetTableCreate,
  resetTableUpdate,
  updateTable,
} from "../actions/tableActions";
import Header from "../components/Header";
import CustomPrimaryButton from "../components/CustomPrimaryButton";
import PopUpModal from "../components/CustomPopUpModal";

import { listFiles, uploadFile, resetFileUpload } from "../actions/fileActions";
import TableButton from "../components/CustomTableButton";
import {
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
} from "../constants/fileConstants";

import { USER_EXPIRED_TOKEN_RESET } from "../constants/userConstants";
import { logout } from "../actions/userActions";

import CustomAlertModal from "../components/CustomAlertModal";

const TableScreen = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const [showTokenModal, setShowTokenModal] = useState(false);

  const [formData, setFormData] = useState({
    folder: "",
    tablename: "",
    file: "",
  });
  const [updateData, setUpdateData] = useState({
    tableId: "",
    tableName: "",
    file: "",
  });
  const [isCreateNewFolder, setIsCreateNewFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [isUploadNewFile, setIsUploadNewFile] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const tableList = useSelector((state) => state.tableList);
  const { loading, error, tables } = tableList;

  const fileList = useSelector((state) => state.file);
  const { listLoading, listError, files } = fileList;

  const tableCreate = useSelector((state) => state.tableCreate);
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
  } = tableCreate || {};

  const tableUpdate = useSelector((state) => state.tableUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    uploadProgress: updateProgress,
  } = tableUpdate || {};

  const tokenExpired = useSelector((state) => state.tokenExpired);
  const { error: TokenMessage } = tokenExpired;

  const isCreatingTable = useRef(false);
  const isUpdatingTable = useRef(false);
  const fileInputRef = useRef(null);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setFormData({ folder: "", tablename: "", file: "" });
    setIsCreateNewFolder(false);
    setNewFolderName("");
    isCreatingTable.current = false;
    dispatch(resetTableCreate());
  }, [dispatch]);

  const handleCloseUpdateModal = useCallback(() => {
    setIsUpdateModalOpen(false);
    setUpdateData({ tableId: "", tableName: "", file: "" });
    setIsUploadNewFile(true);
    setUploadedFile(null);
    isUpdatingTable.current = false;
    dispatch(resetTableUpdate());
  }, [dispatch]);

  const handleCreateResult = useCallback(() => {
    if (isCreatingTable.current) {
      if (createSuccess) {
        toast.success("Table created successfully!");
        handleCloseModal();
      } else if (createError) {
        toast.error(`Error creating table: ${createError}`);
      }
      isCreatingTable.current = false;
    }
    dispatch(resetTableCreate());
  }, [createSuccess, createError, dispatch, handleCloseModal]);

  const handleUpdateResult = useCallback(() => {
    if (isUpdatingTable.current) {
      if (updateSuccess) {
        toast.success(
          `Table ${updateData.tableName} was updated successfully!`,
        );
        handleCloseUpdateModal();
        dispatch(listTables());
      } else if (updateError) {
        toast.error(
          `Error updating Table ${updateData.tableName}. Error: ${updateError}`,
        );
      }
      isUpdatingTable.current = false;
    }
    dispatch(resetTableUpdate());
  }, [
    updateSuccess,
    updateError,
    updateData.tableName,
    dispatch,
    handleCloseUpdateModal,
  ]);

  useEffect(() => {
    dispatch(listTables());
    dispatch(listFiles());
  }, [dispatch]);

  useEffect(() => {
    if (createSuccess || createError) {
      handleCreateResult();
    }
    if (TokenMessage) {
      toast.error(TokenMessage);
      setShowTokenModal(true);
    }
  }, [createSuccess, createError, handleCreateResult, TokenMessage]);

  useEffect(() => {
    if (updateSuccess || updateError) {
      handleUpdateResult();
      setUploadedFile(null);
    }
  }, [updateSuccess, updateError, handleUpdateResult]);

  useEffect(() => {
    return () => {
      dispatch(resetFileUpload());
    };
  }, [dispatch]);

  const columns = [
    { Header: "Tablename", accessor: "Tablename" },
    { Header: "Folder", accessor: "Folder" },
    { Header: "Created At", accessor: "Created at" },
    { Header: "Created By", accessor: "Uploaded by" },
    {
      Header: "Update Table",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <TableButton
            variant="warning"
            size="small"
            onClick={() => handleOpenUpdateModal(row)}
            title="Edit"
          >
            <FaEdit className="text-white" />
          </TableButton>
        </div>
      ),
      is_Action: true,
    },
  ];

  const uniqueFolders = tables
    ? [...new Set(tables.map((table) => table.Folder))]
    : [];
  const handleTokenModalConfirm = () => {
    setShowTokenModal(false);
    dispatch({ type: USER_EXPIRED_TOKEN_RESET });
    dispatch(logout());
  };
  const handleConfirm = () => {
    if (!formData.tablename || !formData.file) {
      toast.error("Please fill in all fields");
      return;
    }

    const folderName = isCreateNewFolder ? newFolderName : formData.folder;

    if (!folderName) {
      toast.error("Please select or create a folder");
      return;
    }

    isCreatingTable.current = true;
    dispatch(createTable(formData.file, formData.tablename, folderName));
  };

  const handleConfirmUpdate = () => {
    const fileId = isUploadNewFile ? uploadedFile.id : updateData.file;
    if (!fileId) {
      toast.error(`Please select or upload a file`);
      return;
    }
    isUpdatingTable.current = true;
    dispatch(updateTable(updateData.tableId, fileId));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleOpenUpdateModal = useCallback((table) => {
    if (table && table.Id && table.Tablename) {
      setUpdateData({
        tableId: table.Id,
        tableName: table.Tablename,
        file: "",
      });
      setIsUpdateModalOpen(true);
    } else {
      toast.error("Error opening update modal. Please try again.");
    }
  }, []);

  const handleNewFolderNameChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9\s_]/g, "");
    setNewFolderName(value);
  };

  const handleCreateFolder = () => {
    if (newFolderName.trim() === "") {
      toast.error("Folder name cannot be empty or only whitespace.");
      return;
    }

    if (uniqueFolders.includes(newFolderName)) {
      toast.error(
        `Foldername "${newFolderName}" already exists, please try another one.`,
      );
    } else {
      setFormData((prevData) => ({
        ...prevData,
        folder: newFolderName,
      }));
      toast.success(`Folder "${newFolderName}" created successfully!`);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      ["xls", "xlsx", "csv"].includes(
        selectedFile.name.split(".").pop().toLowerCase(),
      )
    ) {
      handleUpload(selectedFile);
    } else {
      toast.error("Please select a file with .xls, .xlsx, or .csv extension.");
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = (file) => {
    setIsFileUploading(true);

    dispatch(uploadFile(file))
      .then((result) => {
        if (result && result.type === FILE_UPLOAD_SUCCESS && result.payload) {
          const uploadedFileData = result.payload;
          const fileId =
            uploadedFileData.id ||
            uploadedFileData.Id ||
            uploadedFileData.file_id;

          if (fileId) {
            setUploadedFile(uploadedFileData);
            setUpdateData((prevData) => ({
              ...prevData,
              file: fileId,
            }));
            toast.success("File uploaded successfully!");
            dispatch(resetFileUpload());
          } else {
            throw new Error("No file ID in server response");
          }
        } else if (result && result.type === FILE_UPLOAD_FAIL) {
          throw new Error(result.payload || "File upload failed");
        } else {
          throw new Error("Unexpected result from file upload");
        }
      })
      .catch((error) => {
        toast.error(`Error uploading file: ${error.message}`);
        setUploadedFile(null);
        setUpdateData((prevData) => ({
          ...prevData,
          file: "",
        }));
        dispatch(resetFileUpload());
      })
      .finally(() => {
        setIsFileUploading(false);
        console.log("File upload process completed");
      });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <main className="container mx-auto mt-8 p-4">
        <div className="relative mb-8">
          <h1 className="text-3xl font-bold">Tables</h1>
          <div className="absolute top-0 right-0">
            <CustomPrimaryButton
              size="md"
              textSize="text-2l"
              onClick={handleOpenModal}
            >
              Create Table
            </CustomPrimaryButton>
          </div>
        </div>

        {loading ? (
          <CustomLoadingSpinner
            size={8}
            text="Loading tables..."
            textPosition="bottom"
          />
        ) : error ? (
          <p className="text-red-500">Error: {error}</p>
        ) : (
          <CustomTable columns={columns} data={tables || []} />
        )}

        <PopUpModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
          title="Create Table"
          confirmDisabled={createLoading}
        >
          <form>
            <div className="mb-4 flex items-center justify-between">
              <span className="text-gray-300">Create new Folder</span>
              <Switch
                checked={isCreateNewFolder}
                onChange={setIsCreateNewFolder}
                className={`${
                  isCreateNewFolder ? "bg-blue-600" : "bg-gray-400"
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
              >
                <span
                  className={`${
                    isCreateNewFolder ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                />
              </Switch>
            </div>
            {isCreateNewFolder ? (
              <div className="mb-4">
                <div className="flex items-center mb-2">
                  <label className="block text-gray-300 mr-2">Foldername</label>
                  <div className="relative group">
                    <FaInfoCircle className="text-gray-400 cursor-help" />
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 w-48 z-10">
                      Please use only letters, numbers, spaces, and underscores
                      for your folder name.
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  value={newFolderName}
                  onChange={handleNewFolderNameChange}
                  className="w-full p-2 bg-gray-700 text-white border border-gray-600 rounded"
                  placeholder="Enter new folder name"
                />
                <button
                  type="button"
                  onClick={handleCreateFolder}
                  disabled={!newFolderName.trim()}
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                >
                  Create Folder
                </button>
              </div>
            ) : (
              <div className="mb-4">
                <label className="block text-gray-300">Folder</label>
                <select
                  name="folder"
                  value={formData.folder}
                  onChange={handleChange}
                  className="w-full p-2 bg-gray-700 text-white border border-gray-600 rounded"
                >
                  <option value="">Select Folder</option>
                  {uniqueFolders.map((folder) => (
                    <option key={folder} value={folder}>
                      {folder}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="mb-4">
              <label className="block text-gray-300">Tablename</label>
              <input
                type="text"
                name="tablename"
                value={formData.tablename}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 text-white border border-gray-600 rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-300">File</label>
              <select
                name="file"
                value={formData.file}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 text-white border border-gray-600 rounded"
              >
                <option value="">Select File</option>
                {listLoading ? (
                  <option>Loading files...</option>
                ) : listError ? (
                  <option>Error loading files</option>
                ) : (
                  files.map((file) => (
                    <option key={file.Id} value={file.Id}>
                      {file.Id} {file.Filename}
                    </option>
                  ))
                )}
              </select>
            </div>
          </form>
          {createLoading && (
            <CustomLoadingSpinner
              size={4}
              text="Creating table..."
              textPosition="right"
            />
          )}
        </PopUpModal>

        <PopUpModal
          isOpen={isUpdateModalOpen}
          onClose={handleCloseUpdateModal}
          onConfirm={handleConfirmUpdate}
          title={`Update Table ${updateData.tableName}`}
          confirmDisabled={
            updateLoading ||
            (!isUploadNewFile && !updateData.file) ||
            (isUploadNewFile && !uploadedFile)
          }
        >
          <form>
            <div className="mb-4 flex items-center justify-between">
              <span className="text-gray-300">Upload new File</span>
              <Switch
                checked={isUploadNewFile}
                onChange={setIsUploadNewFile}
                className={`${
                  isUploadNewFile ? "bg-blue-600" : "bg-gray-400"
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
              >
                <span
                  className={`${
                    isUploadNewFile ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                />
              </Switch>
            </div>

            {isUploadNewFile ? (
              <div className="mb-4">
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="hidden"
                  ref={fileInputRef}
                  accept=".xls,.xlsx,.csv"
                />
                <CustomPrimaryButton
                  onClick={handleUploadButtonClick}
                  size="md"
                  textSize="text-2l"
                  disabled={isFileUploading}
                >
                  Upload File
                </CustomPrimaryButton>
                {uploadedFile && (
                  <p className="mt-2 text-green-500">
                    File uploaded:{" "}
                    {uploadedFile.filename ||
                      uploadedFile.name ||
                      "Unknown filename"}
                  </p>
                )}
                {isFileUploading && (
                  <CustomLoadingSpinner
                    size={4}
                    text="Uploading file..."
                    textPosition="right"
                  />
                )}
              </div>
            ) : (
              <div className="mb-4">
                <label className="block text-gray-300">Select File</label>
                <select
                  name="file"
                  value={updateData.file}
                  onChange={handleUpdateChange}
                  className="w-full p-2 bg-gray-700 text-white border border-gray-600 rounded"
                  disabled={isUploadNewFile}
                >
                  <option value="">Select File</option>
                  {listLoading ? (
                    <option>Loading files...</option>
                  ) : listError ? (
                    <option>Error loading files</option>
                  ) : (
                    files.map((file) => (
                      <option key={file.Id} value={file.Id}>
                        {file.Id} {file.Filename}
                      </option>
                    ))
                  )}
                </select>
              </div>
            )}
          </form>
          {updateLoading && (
            <div className="mt-4">
              <progress value={updateProgress} max="100" className="w-full" />
              <p className="mt-2 text-center">{updateProgress}% Updated</p>
            </div>
          )}
        </PopUpModal>

        <CustomAlertModal
          isOpen={showTokenModal}
          onConfirm={handleTokenModalConfirm}
          title="Session Expired"
        >
          <p>{TokenMessage}</p>
        </CustomAlertModal>
      </main>
    </div>
  );
};

export default TableScreen;
