import React from "react";

const CreateFormComponent = ({
  onClose,
  step,
  handleNext,
  handleBack,
  steps,
  currentStep,
  nextDisabled,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-full max-w-3xl h-auto z-60">
        <div className="flex items-center mb-4 overflow-x-auto">
          {steps.map((s, index) => (
            <div key={index} className="flex items-center flex-shrink-0">
              <button
                className={`px-4 py-2 rounded focus:outline-none ${
                  index <= currentStep
                    ? "bg-green-500 text-white"
                    : "bg-gray-700 text-gray-300"
                } transition duration-200`}
              >
                {s.title}
              </button>
              {index < steps.length - 1 && (
                <div
                  className={`h-1 w-16 ${index < currentStep ? "bg-green-500" : "bg-gray-700"} mx-2`}
                />
              )}
            </div>
          ))}
        </div>

        <h2 className="text-xl mb-2 text-green-500">Step: {step.title}</h2>

        <div className="mb-4">{step.fields}</div>

        <div className="flex justify-between mt-4">
          <button
            onClick={handleBack}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
            disabled={currentStep === 0}
          >
            Back
          </button>
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Cancel
          </button>
          {currentStep === steps.length - 1 ? (
            <button
              onClick={step.nextButton.onClick}
              className="px-4 py-2 rounded text-white bg-green-500 hover:bg-green-600"
            >
              {step.nextButton.label}
            </button>
          ) : (
            <button
              onClick={handleNext}
              className={`px-4 py-2 rounded text-white transition duration-200 ${
                nextDisabled ? "bg-gray-500" : "bg-green-500 hover:bg-green-600"
              }`}
              disabled={nextDisabled}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateFormComponent;
