import React from "react";
import {
  userRegisterReducer,
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "./store";
import LoginScreen from "./screens/LoginScreen";
import HomeScreen from "./screens/HomeScreen";
import UserListScreen from "./screens/UserListScreen";
import FileScreen from "./screens/FileScreen";
import ConnectionScreen from "./screens/ConnectionScreen";
import TableScreen from "./screens/TableScreen";
import RegisterScreen from "./screens/RegisterScreen";
import RegisterSuccessScreen from "./screens/RegisterSuccessScreen";

const PrivateRoute = ({ element, ...rest }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin || {};

  return userInfo ? element : <Navigate to="/login" replace />;
};

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/register-success" element={<RegisterSuccessScreen />} />
          <Route
            path="/home"
            element={<PrivateRoute element={<HomeScreen />} />}
          />
          <Route path="/" element={<PrivateRoute element={<HomeScreen />} />} />
          <Route
            path="/connections"
            element={<PrivateRoute element={<ConnectionScreen />} />}
          />
          <Route
            path="/admin/files"
            element={<PrivateRoute element={<FileScreen />} />}
          />
          <Route
            path="/admin/tables"
            element={<PrivateRoute element={<TableScreen />} />}
          />
          <Route
            path="/admin/userlist"
            element={<PrivateRoute element={<UserListScreen />} />}
          />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
