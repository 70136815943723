// CreateTable
export const TABLE_CREATE_REQUEST = "TABLE_CREATE_REQUEST";
export const TABLE_CREATE_FAIL = "TABLE_CREATE_FAIL";
export const TABLE_CREATE_RESET = "TABLE_CREATE_RESET";
export const TABLE_CREATE_PROGRESS = "TABLE_CREATE_PROGRESS";
export const TABLE_CREATE_SUCCESS = "TABLE_CREATE_PROGRESS";

// TableList
export const TABLE_LIST_REQUEST = "TABLE_LIST_REQUEST";
export const TABLE_LIST_SUCCESS = "TABLE_LIST_SUCCESS";
export const TABLE_LIST_FAIL = "TABLE_LIST_FAIL";

// Update Table
export const TABLE_UPDATE_REQUEST = "TABLE_UPDATE_REQUEST";
export const TABLE_UPDATE_FAIL = "TABLE_UPDATE_FAIL";
export const TABLE_UPDATE_RESET = "TABLE_UPDATE_RESET";
export const TABLE_UPDATE_PROGRESS = "TABLE_UPDATE_PROGRESS";
export const TABLE_UPDATE_SUCCESS = "TABLE_UPDATE_PROGRESS";
