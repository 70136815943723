import {
  CONNECTION_LIST_REQUEST,
  CONNECTION_LIST_SUCCESS,
  CONNECTION_LIST_FAIL,
  CONNECTION_TEST_REQUEST,
  CONNECTION_TEST_SUCCESS,
  CONNECTION_TEST_FAIL,
  CONNECTION_CREATE_REQUEST,
  CONNECTION_CREATE_SUCCESS,
  CONNECTION_CREATE_FAIL,
  CONNECTION_DELETE_REQUEST,
  CONNECTION_DELETE_SUCCESS,
  CONNECTION_DELETE_FAIL,
  CONNECTION_SHOW_SCHEMA_REQUEST,
  CONNECTION_SHOW_SCHEMA_SUCCESS,
  CONNECTION_SHOW_SCHEMA_FAIL,
} from "../constants/connectionConstants";
import axios from "axios";
import config from "../config";
import { formatDate } from "../utils/dateTimeUtils";

import {
  EXPIRED_TOKEN_MESSAGE,
  USER_EXPIRED_TOKEN,
} from "../constants/userConstants";

export const listConnections = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONNECTION_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${config.HOST}:${config.PORT}/api/connections/`,
      axiosConfig,
    );
    const formattedData = {
      ...data,
      sql_connections: data.sql_connections.map((connection) => ({
        ...connection,
        created_at: formatDate(connection.created_at),
      })),
    };
    console.log(formattedData);
    dispatch({
      type: CONNECTION_LIST_SUCCESS,
      payload: formattedData,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    dispatch({
      type: CONNECTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createConnection =
  (name, system, host, port, password, privileges, user, database) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CONNECTION_CREATE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.post(
        `${config.HOST}:${config.PORT}/api/connections/create/`,
        {
          name: name,
          system: system,
          host: host,
          port: port,
          password: password,
          privileges: privileges,
          user: user,
          database: database,
        },
        axiosConfig,
      );

      dispatch({
        type: CONNECTION_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
      }
      dispatch({
        type: CONNECTION_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const testConnections =
  (system, host, port, password, user, database) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CONNECTION_TEST_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.post(
        `${config.HOST}:${config.PORT}/api/connections/test/`,
        {
          system: system,
          host: host,
          port: port,
          password: password,
          user: user,
          database: database,
        },
        axiosConfig,
      );
      console.log(host, port, password, user, database);
      dispatch({
        type: CONNECTION_TEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
      }
      dispatch({
        type: CONNECTION_TEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteConnection = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONNECTION_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${config.HOST}:${config.PORT}/api/connections/delete/${id}`,
      axiosConfig,
    );

    dispatch({
      type: CONNECTION_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    dispatch({
      type: CONNECTION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const schemaConnections = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONNECTION_SHOW_SCHEMA_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${config.HOST}:${config.PORT}/api/connections/show-schema/${id}`,
      axiosConfig,
    );
    dispatch({
      type: CONNECTION_SHOW_SCHEMA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    dispatch({
      type: CONNECTION_SHOW_SCHEMA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
