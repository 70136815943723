import axios from "axios";
import {
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  FILE_UPLOAD_PROGRESS,
  FILE_LIST_REQUEST,
  FILE_LIST_SUCCESS,
  FILE_LIST_FAIL,
  FILE_UPLOAD_RESET,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAIL,
  FILE_DOWNLOAD_REQUEST,
  FILE_DOWNLOAD_FAIL,
  FILE_DOWNLOAD_SUCCESS,
} from "../constants/fileConstants";
import config from "../config";
import { handleError } from "../utils/errorMessageUtils";
import { formatDate } from "../utils/dateTimeUtils";

import {
  EXPIRED_TOKEN_MESSAGE,
  USER_EXPIRED_TOKEN,
} from "../constants/userConstants";

export const uploadFile = (file) => async (dispatch, getState) => {
  try {
    dispatch({ type: FILE_UPLOAD_REQUEST });
    console.log("uploadFile action started for file:", file.name);

    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        dispatch({ type: FILE_UPLOAD_PROGRESS, payload: percentCompleted });
      },
    };

    const formData = new FormData();
    formData.append("file", file);

    const apiUrl = `${config.HOST}:${config.PORT}/api/files/file_upload/`;
    const { data } = await axios.post(apiUrl, formData, axiosConfig);
    console.log("Response from server in uploadFile action:", data);

    dispatch({ type: FILE_UPLOAD_SUCCESS, payload: data });
    console.log("FILE_UPLOAD_SUCCESS dispatched with payload:", data);

    return { type: FILE_UPLOAD_SUCCESS, payload: data };
  } catch (error) {
    console.error("Error in uploadFile action:", error);
    console.error(
      "Error response:",
      error.response ? error.response.data : "No response data",
    );

    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }

    const errorMessage = handleError(error);
    dispatch({
      type: FILE_UPLOAD_FAIL,
      payload: errorMessage,
    });
    return { type: FILE_UPLOAD_FAIL, payload: errorMessage };
  }
};

export const resetUploadState = () => (dispatch) => {
  dispatch({ type: FILE_UPLOAD_RESET });
};

export const listFiles = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FILE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const apiUrl = `${config.HOST}:${config.PORT}/api/files/files/`;
    const { data } = await axios.get(apiUrl, axiosConfig);
    const transformedData = data.map((file) => ({
      Id: file.id,
      Filename: file.filename,
      Filetype: file.filetype,
      "Size in Bytes": file.filesize,
      "Uploaded at": formatDate(file.created_at),
      "Uploaded by": file.created_by_username,
    }));

    dispatch({ type: FILE_LIST_SUCCESS, payload: transformedData });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    console.error("File list fetch failed:", error);
    let errorMessage =
      "An unexpected error occurred while fetching the file list";
    if (error.response) {
      errorMessage =
        error.response.data.message || error.response.data || errorMessage;
    } else if (error.request) {
      errorMessage = "No response received from server";
    } else {
      errorMessage = error.message;
    }
    dispatch({
      type: FILE_LIST_FAIL,
      payload: errorMessage,
    });
  }
};

export const deleteFile = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FILE_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${config.HOST}:${config.PORT}/api/files/delete/${id}`,
      axiosConfig,
    );

    dispatch({
      type: FILE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    dispatch({
      type: FILE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const downloadFile = (file) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FILE_DOWNLOAD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
      responseType: "blob",
    };

    const { data } = await axios.get(
      `${config.HOST}:${config.PORT}/api/files/download/${file.Id}/`,
      axiosConfig,
    );

    const url = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${file.Filename}`;
    document.body.appendChild(a);
    a.click();
    a.remove();

    dispatch({
      type: FILE_DOWNLOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    dispatch({
      type: FILE_DOWNLOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetFileUpload = () => (dispatch) => {
  dispatch({ type: FILE_UPLOAD_RESET });
};
