export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DEACTIVATE_RESET";

export const USER_DEACTIVATE_REQUEST = "USER_DEACTIVATE_REQUEST";
export const USER_DEACTIVATE_SUCCESS = "USER_DEACTIVATE_SUCCESS";
export const USER_DEACTIVATE_FAIL = "USER_DEACTIVATE_FAIL";
export const USER_DEACTIVATE_RESET = "USER_DEACTIVATE_RESET";

export const USER_ACTIVATE_REQUEST = "USER_ACTIVATE_REQUEST";
export const USER_ACTIVATE_SUCCESS = "USER_ACTIVATE_SUCCESS";
export const USER_ACTIVATE_FAIL = "USER_ACTIVATE_FAIL";
export const USER_ACTIVATE_RESET = "USER_ACTIVATE_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_RESET = "USER_CREATE_RESET";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";

export const USER_EXPIRED_TOKEN = "USER_EXPIRED_TOKEN";
export const USER_EXPIRED_TOKEN_RESET = "USER_EXPIRED_TOKEN_REST";
export const EXPIRED_TOKEN_MESSAGE = "try to log in again";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
