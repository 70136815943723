import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../components/Header";
import CustomPrimaryButton from "../components/CustomPrimaryButton";
import CustomPopUpModal from "../components/CustomPopUpModal";
import CustomTable from "../components/CustomTable";
import CustomLoadingSpinner from "../components/CustomLoadingSpinner";
import {
  uploadFile,
  listFiles,
  resetUploadState,
  deleteFile,
  downloadFile,
} from "../actions/fileActions";
import TableButton from "../components/CustomTableButton";
import { FaDownload, FaTrash } from "react-icons/fa";
import {
  FILE_DELETE_RESET,
  FILE_DOWNLOAD_RESET,
} from "../constants/fileConstants";
import CustomAlertModal from "../components/CustomAlertModal";
import { logout } from "../actions/userActions";
import { USER_EXPIRED_TOKEN_RESET } from "../constants/userConstants";

const FileScreen = () => {
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);

  const dispatch = useDispatch();
  const fileState = useSelector((state) => state.file);
  const {
    uploadLoading,
    uploadError,
    uploadSuccess,
    uploadProgress,
    listLoading,
    listError,
    files,
  } = fileState;

  const fileDelete = useSelector((state) => state.fileDelete);
  const { error: errorDelete, success: successDelete } = fileDelete;
  const fileDownload = useSelector((state) => state.fileDownload);
  const { error: errorDownload, success: successDownload } = fileDownload;
  const tokenExpired = useSelector((state) => state.tokenExpired);
  const { error: TokenMessage } = tokenExpired;
  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(listFiles());
  }, [dispatch]);

  useEffect(() => {
    if (uploadSuccess) {
      toast.success("File uploaded successfully!");
      setFile(null);
      setIsModalOpen(false);
      dispatch(resetUploadState());
      dispatch(listFiles());
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
    if (uploadError) {
      toast.error(`Error during file upload: ${uploadError}`);
      dispatch(resetUploadState());
    }
    if (TokenMessage) {
      toast.error(TokenMessage);
      setShowTokenModal(true);
    }
  }, [uploadSuccess, uploadError, dispatch, TokenMessage]);

  useEffect(() => {
    if (successDelete) {
      toast.success("File deleted successfully!");
      dispatch({ type: FILE_DELETE_RESET });
      setIsDeleteModalOpen(false);
      dispatch(listFiles());
    }
    if (errorDelete) {
      toast.error(`Error deleting file: ${errorDelete}`);
      dispatch({ type: FILE_DELETE_RESET });
    }
    if (successDownload) {
      toast.success("File downloaded successfully!");
      dispatch({ type: FILE_DOWNLOAD_RESET });
    }
    if (errorDownload) {
      toast.error(`Error downloading file: ${errorDownload}`);
      dispatch({ type: FILE_DOWNLOAD_RESET });
    }
  }, [successDelete, errorDelete, dispatch, successDownload, errorDownload]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      ["xls", "xlsx", "csv"].includes(
        selectedFile.name.split(".").pop().toLowerCase(),
      )
    ) {
      setFile(selectedFile);
      setIsModalOpen(true);
    } else {
      toast.error("Please select a file with .xls, .xlsx, or .csv extension.");
    }
  };
  const handleTokenModalConfirm = () => {
    setShowTokenModal(false);
    dispatch({ type: USER_EXPIRED_TOKEN_RESET });
    dispatch(logout());
  };
  const handleUpload = () => {
    if (!file) return;
    dispatch(uploadFile(file));
  };

  const handleDeleteFile = (file) => {
    setFileToDelete(file);
    setIsDeleteModalOpen(true);
  };
  const handleDownloadFile = (file) => {
    dispatch(downloadFile(file));
  };

  const handleConfirmDelete = () => {
    dispatch(deleteFile(fileToDelete.Id));
  };

  const handleButtonClick = () => {
    dispatch(resetUploadState());
    fileInputRef.current.click();
  };

  const columns = [
    { Header: "Filename", accessor: "Filename" },
    { Header: "Filetype", accessor: "Filetype" },
    { Header: "Size in Bytes", accessor: "Size in Bytes" },
    { Header: "Uploaded at", accessor: "Uploaded at" },
    { Header: "Uploaded by", accessor: "Uploaded by" },
    {
      Header: "Download",
      Cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <TableButton
              variant="success"
              size="small"
              onClick={() => handleDownloadFile(row)}
              title="Download"
            >
              <FaDownload className="text-white" />
            </TableButton>
          </div>
        );
      },
      is_Action: true,
    },
    {
      Header: "Delete",
      Cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <TableButton
              variant="danger"
              size="small"
              onClick={() => handleDeleteFile(row)}
              title="Delete"
            >
              <FaTrash className="text-white" />
            </TableButton>
          </div>
        );
      },
      is_Action: true,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <main className="container mx-auto mt-8 p-4">
        <div className="relative mb-8">
          <h1 className="text-3xl font-bold">Files</h1>
          <div className="absolute top-0 right-0">
            <input
              type="file"
              onChange={handleFileChange}
              className="hidden"
              ref={fileInputRef}
              accept=".xls,.xlsx,.csv"
            />
            <CustomPrimaryButton
              onClick={handleButtonClick}
              size="md"
              textSize="text-2l"
              disabled={uploadLoading}
            >
              Upload File
            </CustomPrimaryButton>
          </div>
        </div>

        {listLoading ? (
          <CustomLoadingSpinner
            size={8}
            text="Loading files..."
            textPosition="bottom"
          />
        ) : listError ? (
          <p className="text-red-500">Error: {listError}</p>
        ) : (
          <CustomTable columns={columns} data={files} />
        )}

        <CustomPopUpModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleUpload}
          title="Confirm Upload"
        >
          <p className="mb-4">Do you want to upload the file: {file?.name}?</p>
          {uploadLoading && (
            <div className="mt-4">
              <progress value={uploadProgress} max="100" className="w-full" />
              <p className="mt-2 text-center">{uploadProgress}% Uploaded</p>
            </div>
          )}
        </CustomPopUpModal>

        {/* Delete confirmation modal */}
        <CustomPopUpModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
          title="Confirm Delete"
        >
          <p>
            Are you sure you want to delete the file: {fileToDelete?.Filename}?
          </p>
        </CustomPopUpModal>
        <CustomAlertModal
          isOpen={showTokenModal}
          onConfirm={handleTokenModalConfirm}
          title="Session Expired"
        >
          <p>{TokenMessage}</p>
        </CustomAlertModal>
      </main>
    </div>
  );
};

export default FileScreen;
