import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_CREATE_FAIL,
  USER_CREATE_SUCCESS,
  USER_CREATE_REQUEST,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_SUCCESS,
  USER_DEACTIVATE_FAIL,
  USER_DEACTIVATE_REQUEST,
  USER_DEACTIVATE_SUCCESS,
  USER_CREATE_RESET,
  USER_DEACTIVATE_RESET,
  USER_ACTIVATE_RESET,
  USER_DELETE_RESET,
  USER_EXPIRED_TOKEN,
  USER_EXPIRED_TOKEN_RESET,
} from "../constants/userConstants";

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT_REQUEST:
      return { ...state, loadingLogout: true };
    case USER_LOGOUT_SUCCESS:
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userExpiredTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_EXPIRED_TOKEN:
      console.log(action.payload);
      return { error: action.payload };
    case USER_EXPIRED_TOKEN_RESET:
      return {};
    default:
      return state;
  }
};

export const usersListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        loading: true,
      };
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload,
      };
    case USER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_LIST_RESET:
      return {
        users: [],
      };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return {
        loading: true,
      };
    case USER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const userDeactivateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DEACTIVATE_REQUEST:
      return {
        loading: true,
      };
    case USER_DEACTIVATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_DEACTIVATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_DEACTIVATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userActivateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVATE_REQUEST:
      return {
        loading: true,
      };
    case USER_ACTIVATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_ACTIVATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_ACTIVATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case USER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case USER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
