import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_CREATE_FAIL,
  USER_CREATE_SUCCESS,
  USER_CREATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_SUCCESS,
  USER_DEACTIVATE_FAIL,
  USER_DEACTIVATE_REQUEST,
  USER_DEACTIVATE_SUCCESS,
  USER_EXPIRED_TOKEN,
  EXPIRED_TOKEN_MESSAGE,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
} from "../constants/userConstants";

import config from "../config";

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await axios.post(
      `${config.HOST}:${config.PORT}/api/users/login`,
      { username, password },
    );

    if (!data || !data.access) {
      throw new Error("Access token not received from server");
    }

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LOGOUT_REQUEST });
    dispatch({ type: USER_LIST_RESET });
    const {
      userLogin: { userInfo },
    } = getState();

    if (!userInfo || !userInfo.access) {
      console.error("User info or access token is missing");
      dispatch({ type: USER_LOGOUT_SUCCESS });
      dispatch({ type: USER_LIST_RESET });
      localStorage.removeItem("userInfo");
      window.location.href = "/login";
      return;
    }

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    await axios.post(
      `${config.HOST}:${config.PORT}/api/users/logout`,
      {},
      axiosConfig,
    );

    dispatch({ type: USER_LOGOUT_SUCCESS });
    dispatch({ type: USER_LIST_RESET });
    localStorage.removeItem("userInfo");
    window.location.href = "/login";
  } catch (error) {
    console.error("Logout error:", error);
    dispatch({ type: USER_LOGOUT_SUCCESS });
    dispatch({ type: USER_LIST_RESET });
    localStorage.removeItem("userInfo");
    window.location.href = "/login";
  }
};

export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${config.HOST}:${config.PORT}/api/users/`,
      axiosConfig,
    );
    console.log(data);
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${config.HOST}:${config.PORT}/api/users/delete/${id}`,
      axiosConfig,
    );

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const deactivateUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DEACTIVATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${config.HOST}:${config.PORT}/api/users/deactivate/${id}`,
      {},
      axiosConfig,
    );
    console.log("Used Token:");
    dispatch({
      type: USER_DEACTIVATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    dispatch({
      type: USER_DEACTIVATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const activateUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_ACTIVATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${config.HOST}:${config.PORT}/api/users/activate/${id}`,
      {},
      axiosConfig,
    );

    dispatch({
      type: USER_ACTIVATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    dispatch({
      type: USER_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const createUser =
  (firstName, lastName, email, password, isAdmin) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_CREATE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      console.log(isAdmin);
      const { data } = await axios.post(
        `${config.HOST}:${config.PORT}/api/users/create`,
        {
          email: email,
          first_name: firstName,
          last_name: lastName,
          password: password,
          is_admin: isAdmin,
        },
        axiosConfig,
      );

      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
      }
      dispatch({
        type: USER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateUser =
  (id, firstName, lastName, email, isAdmin) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(
        `${config.HOST}:${config.PORT}/api/users/update/${id}`,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          is_admin: isAdmin,
        },
        axiosConfig,
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
      }
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getTokenStatus = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${config.HOST}:${config.PORT}/api/users/status`,
      axiosConfig,
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
  }
};

export const register =
  (
    username,
    email,
    first_name,
    last_name,
    phone_number,
    address,
    subdomain,
    password,
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const { data } = await axios.post(
        `${config.HOST}:${config.PORT}/api/users/register`,
        {
          username,
          email,
          first_name,
          last_name,
          phone_number,
          address,
          subdomain,
          password,
        },
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
