import axios from "axios";
import {
  TABLE_LIST_REQUEST,
  TABLE_LIST_SUCCESS,
  TABLE_LIST_FAIL,
  TABLE_CREATE_REQUEST,
  TABLE_CREATE_FAIL,
  TABLE_CREATE_SUCCESS,
  TABLE_CREATE_RESET,
  TABLE_UPDATE_REQUEST,
  TABLE_UPDATE_PROGRESS,
  TABLE_UPDATE_SUCCESS,
  TABLE_UPDATE_FAIL,
  TABLE_UPDATE_RESET,
} from "../constants/tableConstants";
import config from "../config";
import { handleError } from "../utils/errorMessageUtils";
import { formatDate } from "../utils/dateTimeUtils";
import {
  EXPIRED_TOKEN_MESSAGE,
  USER_EXPIRED_TOKEN,
} from "../constants/userConstants";

export const listTables = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TABLE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const apiUrl = `${config.HOST}:${config.PORT}/api/table_management/customer_tables/`;
    const { data } = await axios.get(apiUrl, axiosConfig);

    const transformedData = data.map((table) => ({
      Id: table.id,
      Tablename: table.table_name,
      Folder: table.table_folder,
      "Created at": formatDate(table.created_at),
      "Uploaded by": table.created_by_username,
    }));

    dispatch({
      type: TABLE_LIST_SUCCESS,
      payload: transformedData,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
    }
    const errorMessage = handleError(error);
    dispatch({
      type: TABLE_LIST_FAIL,
      payload: errorMessage,
    });
  }
};

export const createTable =
  (fileId, tableName, folderName) => async (dispatch, getState) => {
    try {
      dispatch({ type: TABLE_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const apiUrl = `${config.HOST}:${config.PORT}/api/table_management/create_customer_table/`;
      const response = await axios.post(
        apiUrl,
        { file_id: fileId, table_name: tableName, folder_name: folderName },
        axiosConfig,
      );

      dispatch({ type: TABLE_CREATE_SUCCESS, payload: response.data });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
      }
      const errorMessage = handleError(error);
      console.error("Table creation failed:", errorMessage);
      dispatch({
        type: TABLE_CREATE_FAIL,
        payload: errorMessage,
      });
    }
  };

export const resetTableCreate = () => (dispatch) => {
  dispatch({ type: TABLE_CREATE_RESET });
};

export const updateTable = (tableId, fileId) => async (dispatch, getState) => {
  try {
    dispatch({ type: TABLE_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      onUpdateProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        dispatch({ type: TABLE_UPDATE_PROGRESS, payload: percentCompleted });
      },
    };

    const apiUrl = `${config.HOST}:${config.PORT}/api/table_management/update_table_by_file/${tableId}/${fileId}/`;
    const response = await axios.post(
      apiUrl,
      { table_id: tableId, file_id: fileId },
      axiosConfig,
    );

    dispatch({ type: TABLE_UPDATE_SUCCESS, payload: response.data });
  } catch (error) {
    const errorMessage = handleError(error);
    console.error("Table creation failed:", errorMessage);
    dispatch({
      type: TABLE_UPDATE_FAIL,
      payload: errorMessage,
    });
  }
};

export const resetTableUpdate = () => (dispatch) => {
  dispatch({ type: TABLE_UPDATE_RESET });
};
