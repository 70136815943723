export const CONNECTION_LIST_REQUEST = "CONNECTION_LIST_REQUEST";
export const CONNECTION_LIST_SUCCESS = "CONNECTION_LIST_SUCCESS";
export const CONNECTION_LIST_FAIL = "CONNECTION_LIST_FAIL";
export const CONNECTION_LIST_REST = "CONNECTION_LIST_REST";

export const CONNECTION_TEST_REQUEST = "CONNECTION_TEST_REQUEST";
export const CONNECTION_TEST_SUCCESS = "CONNECTION_TEST_SUCCESS";
export const CONNECTION_TEST_FAIL = "CONNECTION_TEST_FAIL";
export const CONNECTION_TEST_REST = "CONNECTION_TEST_REST";

export const CONNECTION_CREATE_REQUEST = "CONNECTION_CREATE_REQUEST";
export const CONNECTION_CREATE_SUCCESS = "CONNECTION_CREATE_SUCCESS";
export const CONNECTION_CREATE_FAIL = "CONNECTION_CREATE_FAIL";
export const CONNECTION_CREATE_REST = "CONNECTION_CREATE_REST";

export const CONNECTION_DELETE_REQUEST = "CONNECTION_DELETE_REQUEST";
export const CONNECTION_DELETE_SUCCESS = "CONNECTION_DELETE_SUCCESS";
export const CONNECTION_DELETE_FAIL = "CONNECTION_DELETE_FAIL";
export const CONNECTION_DELETE_REST = "CONNECTION_DELETE_REST";

export const CONNECTION_SHOW_SCHEMA_REQUEST = "CONNECTION_SHOW_SCHEMA_REQUEST";
export const CONNECTION_SHOW_SCHEMA_SUCCESS = "CONNECTION_SHOW_SCHEMA_SUCCESS";
export const CONNECTION_SHOW_SCHEMA_FAIL = "CONNECTION_SHOW_SCHEMA_FAIL";
export const CONNECTION_SHOW_SCHEMA_REST = "CONNECTION_SHOW_SCHEMA_REST";
