import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userActions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomInput from "../components/CustomInput";
import CustomPrimaryButton from "../components/CustomPrimaryButton";
import CustomLoadingSpinner from "../components/CustomLoadingSpinner";

const RegisterScreen = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [subdomainPrefix, setSubdomainPrefix] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  // Check if registration is successful
  useEffect(() => {
    if (userInfo) {
      navigate("/register-success");
    }
  }, [navigate, userInfo]);

  const handleRegister = (e) => {
    e.preventDefault();

    // Regular expressions for validation
    const namePattern = /^[a-zA-ZäöüÄÖÜß]+$/; // Only letters allowed
    const phonePattern = /^[0-9]+$/; // Only digits allowed for phone numbers
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/; // At least 8 characters, 1 uppercase, 1 number, 1 special character
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Valid email pattern

    // Convert email to lowercase
    const normalizedEmail = email.toLowerCase();

    // Validate first name and last name (no numbers or special characters allowed)
    if (!namePattern.test(first_name)) {
      toast.warning(
        "First name should not contain numbers or special characters.",
      );
      return;
    }

    if (!namePattern.test(last_name)) {
      toast.warning(
        "Last name should not contain numbers or special characters.",
      );
      return;
    }

    // Validate email (must contain @ and a valid domain)
    if (!emailPattern.test(normalizedEmail)) {
      toast.warning("Please enter a valid email address.");
      return;
    }

    // Validate phone number (only digits allowed)
    if (!phonePattern.test(phone_number)) {
      toast.warning("Phone number should contain only digits.");
      return;
    }

    // Validate password (must meet security standards)
    if (!passwordPattern.test(password)) {
      toast.warning(
        "Password must be at least 8 characters long, include an uppercase letter, a number, and a special character.",
      );
      return;
    }

    const subdomain = `${subdomainPrefix}`;

    // Dispatch the register action with the validated data
    dispatch(
      register(
        username,
        normalizedEmail, // Use lowercase email
        first_name,
        last_name,
        phone_number,
        address,
        subdomain,
        password,
      ),
    );
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-800 to-gray-900 font-sans">
      <div className="bg-gray-800 p-12 rounded-xl shadow-2xl w-full max-w-2xl text-white">
        <div className="text-center mb-10">
          <h1 className="text-4xl font-semibold text-green-300">
            Create your zAKI Account
          </h1>
          <p className="mt-2 text-gray-400 text-sm">
            Fill in the form below to create your account
          </p>
        </div>
        <form onSubmit={handleRegister} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <CustomInput
              label="First Name"
              id="first_name"
              type="text"
              placeholder="Enter your first name"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <CustomInput
              label="Last Name"
              id="last_name"
              type="text"
              placeholder="Enter your last name"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <CustomInput
              label="Username"
              id="username"
              type="text"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <CustomInput
              label="Phone Number"
              id="phone_number"
              type="text"
              placeholder="Enter your phone number"
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <CustomInput
            label="Email"
            id="email"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <CustomInput
            label="Address"
            id="address"
            type="text"
            placeholder="Enter your address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />

          <div className="flex items-center">
            <div className="flex-grow">
              <CustomInput
                label="Subdomain"
                id="subdomainPrefix"
                type="text"
                placeholder="Enter your subdomain prefix"
                value={subdomainPrefix}
                onChange={(e) => setSubdomainPrefix(e.target.value)}
              />
            </div>
            <span className="ml-2 text-gray-400 text-lg relative top-5">
              .ai4bi.de
            </span>
          </div>

          <CustomInput
            label="Password"
            id="password"
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <CustomPrimaryButton
            type="submit"
            disabled={loading}
            fullWidth="true"
          >
            {loading ? (
              <CustomLoadingSpinner size={6} text="Registering..." />
            ) : (
              "Create Space"
            )}
          </CustomPrimaryButton>
        </form>

        <div className="mt-8 text-center">
          <p className="text-gray-400">
            Already have an account?
            <button
              onClick={() => navigate("/login")}
              className="text-green-400 hover:underline ml-2"
            >
              Login here
            </button>
          </p>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
};

export default RegisterScreen;
