import React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";

const RegisterSuccessScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Hole die Registrierungsinformationen aus dem Redux-Store
  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo } = userRegister;

  const handleLoginNavigation = () => {
    console.log("Navigating to login screen...");
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-800 to-gray-900">
      <div className="bg-gray-800 p-12 rounded-xl shadow-2xl w-full max-w-lg text-white text-center">
        <h1 className="text-4xl font-semibold text-green-300 mb-4">
          Registration Successful!
        </h1>
        {userInfo && (
          <p className="text-gray-400 mb-8">
            Your account with username{" "}
            <span className="text-green-400">{userInfo.username}</span> has been
            created. Please confirm your email address:{" "}
            <span className="text-green-400">{userInfo.email}</span> to activate
            your account.
          </p>
        )}
        <button
          onClick={handleLoginNavigation}
          className="text-white bg-green-500 px-6 py-3 rounded-lg font-semibold"
        >
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default RegisterSuccessScreen;
