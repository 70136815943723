import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import CustomAlertModal from "../components/CustomAlertModal";
import { USER_EXPIRED_TOKEN_RESET } from "../constants/userConstants";
import { getTokenStatus, listUsers, logout } from "../actions/userActions";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const [showTokenModal, setShowTokenModal] = useState(false);
  const tokenExpired = useSelector((state) => state.tokenExpired);
  const { error: TokenMessage } = tokenExpired;

  useEffect(() => {
    dispatch(getTokenStatus());
    if (TokenMessage) {
      toast.error(TokenMessage);
      setShowTokenModal(true);
    }
  }, [TokenMessage]);
  const handleTokenModalConfirm = () => {
    setShowTokenModal(false);
    dispatch({ type: USER_EXPIRED_TOKEN_RESET });
    dispatch(logout());
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      <main className="container mx-auto mt-8 p-4">
        <h1 className="text-3xl font-bold mb-4">Welcome to the Home Screen</h1>
        <p>Main Content</p>
      </main>
      <CustomAlertModal
        isOpen={showTokenModal}
        onConfirm={handleTokenModalConfirm}
        title="Session Expired"
      >
        <p>{TokenMessage}</p>
      </CustomAlertModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
    </div>
  );
};

export default HomeScreen;
