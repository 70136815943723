import React from "react";

const PopUpModal = ({ isOpen, onClose, onConfirm, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 text-white rounded-lg p-6 shadow-lg w-11/12 md:max-w-3xl lg:max-w-4xl">
        <h2 className="text-2xl font-bold mb-4 text-green-500">{title}</h2>
        <div className="mb-4">{children}</div>
        <div className="flex justify-end space-x-2">
          {/* Cancel Button only shown if onClose is provided */}
          {onClose && (
            <button
              onClick={onClose}
              className="bg-gray-500 hover:bg-gray-600 text-black font-bold py-2 px-4 rounded flex items-center"
            >
              Cancel
            </button>
          )}
          {/* Confirm Button only shown if onConfirm is provided */}
          {onConfirm && (
            <button
              onClick={onConfirm}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopUpModal;
