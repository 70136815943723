import React from "react";

const variantStyles = {
  danger: "bg-gray-500 hover:bg-blue-500 focus:ring-blue-400",
  warning: "bg-gray-500 hover:bg-blue-500 focus:ring-blue-400",
  success: "bg-green-700 hover:bg-green-800 focus:ring-green-400",
  primary: "bg-green-500 hover:bg-blue-500 focus:ring-blue-400",
};

const sizeStyles = {
  small: "text-sm py-2 px-3",
  medium: "text-md py-3 px-4",
  large: "text-lg py-4 px-5",
};

const TableButton = ({
  children,
  onClick,
  variant = "primary",
  size = "medium",
  disabled = false,
}) => {
  const buttonClasses = `
        ${variantStyles[variant] || variantStyles.primary} 
        ${sizeStyles[size] || sizeStyles.medium} 
        text-white font-bold rounded focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-300 
        ${disabled ? "opacity-50 cursor-not-allowed" : ""}
    `;

  return (
    <button className={buttonClasses} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default TableButton;
