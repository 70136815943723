// Message.js
import React from "react";

const Message = ({ message, type }) => {
  const messageStyles = {
    error: "bg-red-600 text-white",
    success: "bg-green-500 text-white",
    info: "bg-blue-500 text-white",
    warning: "bg-yellow-500 text-white",
  };

  return (
    <div className={`p-4 rounded-lg flex items-center ${messageStyles[type]}`}>
      {type === "error" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-2"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm-.293-7.293a1 1 0 011.414 0L10 12.414l.293-.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 111.414-1.414L10 12.414l.293-.293z"
            clipRule="evenodd"
          />
        </svg>
      )}
      {type === "success" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-2"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.293-9.293a1 1 0 00-1.414 0L10 12.586l-1.879-1.879a1 1 0 00-1.414 1.414l2.293 2.293a1 1 0 001.414 0l3-3a1 1 0 000-1.414z"
            clipRule="evenodd"
          />
        </svg>
      )}
      {type === "info" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-2"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-6a1 1 0 112 0v3a1 1 0 11-2 0v-3zm0-4a1 1 0 112 0v1a1 1 0 11-2 0V8z"
            clipRule="evenodd"
          />
        </svg>
      )}
      {type === "warning" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-2"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm-.293-7.293a1 1 0 011.414 0L10 12.414l.293-.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 111.414-1.414L10 12.414l.293-.293z"
            clipRule="evenodd"
          />
        </svg>
      )}
      <span>{message}</span>
    </div>
  );
};

export default Message;
