import { ERROR_MESSAGES } from "../constants/errorMessages";

export const handleError = (error) => {
  console.error("API call failed:", error);
  let errorMessage = ERROR_MESSAGES.UNEXPECTED;

  if (error.response) {
    console.error("Error response:", error.response);
    if (error.response.status === 400) {
      // Handle Bad Request errors
      errorMessage =
        error.response.data.detail ||
        error.response.data.message ||
        "Bad Request: Invalid input";
    } else if (error.response.status === 401) {
      errorMessage = ERROR_MESSAGES.UNAUTHORIZED;
    } else if (error.response.status === 404) {
      errorMessage = ERROR_MESSAGES.NOT_FOUND;
    } else {
      errorMessage =
        error.response.data.detail ||
        error.response.data.message ||
        error.response.data ||
        ERROR_MESSAGES.UNEXPECTED;
    }
  } else if (error.request) {
    console.error("Error request:", error.request);
    errorMessage = ERROR_MESSAGES.NO_RESPONSE;
  } else {
    console.error("Error message:", error.message);
    errorMessage = error.message || ERROR_MESSAGES.NETWORK_ERROR;
  }

  console.log("Returned error message:", errorMessage);
  return errorMessage;
};
