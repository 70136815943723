// LoadingMessage.js
import React from "react";

const LoadingMessage = ({ message = "Loading..." }) => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-green-500"></div>
      <span className="ml-4 text-xl">{message}</span>
    </div>
  );
};

export default LoadingMessage;
