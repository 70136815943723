import {
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_RESET,
  FILE_LIST_REQUEST,
  FILE_LIST_SUCCESS,
  FILE_LIST_FAIL,
  FILE_DELETE_FAIL,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_REQUEST,
  FILE_DELETE_RESET,
  FILE_DOWNLOAD_REQUEST,
  FILE_DOWNLOAD_RESET,
  FILE_DOWNLOAD_FAIL,
  FILE_DOWNLOAD_SUCCESS,
} from "../constants/fileConstants";

export const fileReducer = (state = { files: [] }, action) => {
  switch (action.type) {
    case FILE_UPLOAD_REQUEST:
      return { ...state, uploadLoading: true };
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        uploadSuccess: true,
        fileInfo: action.payload,
      };
    case FILE_UPLOAD_FAIL:
      return { ...state, uploadLoading: false, uploadError: action.payload };
    case FILE_UPLOAD_PROGRESS:
      return { ...state, uploadProgress: action.payload };
    case FILE_UPLOAD_RESET:
      return {
        ...state,
        uploadLoading: false,
        uploadSuccess: false,
        uploadError: null,
        uploadProgress: 0,
      };
    case FILE_LIST_REQUEST:
      return { ...state, listLoading: true };
    case FILE_LIST_SUCCESS:
      return { ...state, listLoading: false, files: action.payload };
    case FILE_LIST_FAIL:
      return { ...state, listLoading: false, listError: action.payload };
    default:
      return state;
  }
};
export const fileDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_DELETE_REQUEST:
      return {
        loading: true,
      };
    case FILE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case FILE_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FILE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const fileDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_DOWNLOAD_REQUEST:
      return {
        loading: true,
      };
    case FILE_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case FILE_DOWNLOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FILE_DOWNLOAD_RESET:
      return {};
    default:
      return state;
  }
};
